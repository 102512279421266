(function ($) {
  Drupal.behaviors.editorialProductV1 = {
    attach: function (context) {
      var $mppContainer = $('.js-mpp-container', context);
      var $gridCount = $('.js-mpp-grid__count--number', $mppContainer);
      var $products = $('.js-mpp-grid-item-product', $mppContainer);

      if (!$mppContainer.hasClass('js-mpp-grid-filterable-items-wrapper')) {
        $gridCount.text($products.length).parent().removeClass('hidden');
      }
    }
  };
})(jQuery);
